<template>
  <v-row>
    <v-col cols="6">
      <v-card>
        <EditarProveedor class="px-10" />
      </v-card>
    </v-col>
    <v-col cols="6" text-center>
      <v-img
        fluid
        src="../../../../public/img/proveedor.svg"
        class="imagen-personalizada"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      code: "",
    };
  },
  components: {
    EditarProveedor: () =>
      import(
        "@/components/folderEntities/Proveedor/EditarProveedorComponet.vue"
      ),
  },
  mounted() {},
};
</script>

<style scoped>
.imagen-personalizada {
  margin: auto;
  padding: auto;
  max-width: 80%; /* Ajusta el valor según tus necesidades */
  height: auto; /* Esto asegura que la altura se ajuste automáticamente */
}
</style>
